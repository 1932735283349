// Root level location to store all the custom colours

// Background colours
$valid-bg: rgba(0, 148, 204, 0.24);
$invalid-bg: rgba(204, 0, 0, 0.24);
$error-bg: rgba(228, 26, 26, 0.722);
$error-light-bg: rgba(228, 26, 26, 0.12);
$pending-bg: rgba(68, 71, 78, 0.10);

$chat-blue-bg: #afdbec;
$chat-orange-bg: #ffe4b5;
$chat-green-bg: #8fbc8f;
$chat-dark-blue-bg: #3e93b8;
$chat-dark-orange-bg: #ab771c;
$chat-dark-green-bg: #5e955e;

// Colours
$expired: #767777; // neutral 50
$disabled: rgba(0, 0, 0, 0.38);
$deleted: #737780; // neutral varient 50
$pending: #767777; // neutral 50
$warn: #ffff00;
$info: #9BBCDA;
$inactive: #474747;

$thrive-24-disabled: #474747;
$thrive-24-disabled-dark: #dedede;

$therapist-online-green: #49DD35;
$therapist-online-dark-green: #19671D;
$therapist-offline-red: #FF0019;

$light-blue-hover: #CFE8FF;

$therapist: #014CA7;
$therapist-light: #78a9e3;
$patient: #e79552;
$moment-link: #e59fb9;

$black: #000000;
$green: #17c143;
$white: #ffffff;
$orange: #ffa500;
$red: #d93939;
$blue: #0959A1;
$pink: #f46363;

$neutral-10: #1a1c1c;
$neutral-20: #2f3131;
$neutral-25: #3a3c3c;
$neutral-35: #49454F;
$neutral-40: #5d5f5f;
$neutral-60: #909191;
$neutral-70: #aaabab;
$neutral-80: #D9D9D9;
$neutral-95: #E4E4E4;
$neutral-97: #f0f0f0;
$neutral-98: #f3f3f3;
$neutral-99: #fdfcff;

// Theme colours
$theme-primary: var(--mat-sys-primary);
$theme-secondary: var(--mat-sys-secondary);
$theme-tertiary: var(--mat-sys-tertiary);
$theme-neutral: var(--mat-sys-neutral10);
$theme-error: var(--mat-sys-error);

$primary-35: #00315e;
$primary-50: #002445;
$primary-60: #00396C;
$primary-70: #0294CC;
$primary-90: #86ade7;
$primary-95: #a5c8ff;

// Border colours
$border-05: rgba(0, 0, 0, 0.05);
$border-2: rgba(0, 0, 0, 0.2);
$border-12: rgba(0, 0, 0, 0.12);
$border-14: rgba(0, 0, 0, 0.14);
$border-blue: rgba(32, 33, 36, 0.28);
$border-light: rgba(255, 255, 255, 0.8);
$border-opaque-blue: rgba(0, 36, 69, 0.1);

$grey-divider: #A6A6A6;
