/* You can add global styles to this file, and also import other style files */
@use "fonts" as fonts;
@use 'src/assets/theme/variables' as variables;

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  scrollbar-color: variables.$theme-primary variables.$white;
}

h1, h2, h3 {
  font-weight: 400;
}
h4, h5, h6 {
  font-weight: 500;
}

.hide {
  visibility: hidden !important;
}

.only-on-dark {
  display: none;
}

// Tab styles
.mat-mdc-tab-group.content-tabs {
  .mat-mdc-tab-header {
    background: var(--mat-sys-primary) !important;
    padding: 6px 6px 0;
    margin: -10px 0px 0px -20px;
  }
  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-color: variables.$border-opaque-blue;
  }

  .mdc-tab.mat-mdc-tab {
    &:after {
      border-left: 1px solid variables.$border-opaque-blue;
    }

    &.mdc-tab--active.mdc-tab-indicator--active {
      background: variables.$white;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      padding: 0 10px;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
    }
  }
}

// Shared help icon
.help-icon {
  margin-left: 5px;
  font-size: 18px;
  color: variables.$neutral-60;

  &:hover {
    cursor: help;
  }
}

// Truncate long overflowing text in material list
.mat-mdc-list-item.mat-mdc-list-option {
  > .mdc-list-item__content > .mdc-list-item__primary-text {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
}

html .prefers-color-scheme-dark,
body.prefers-color-scheme-dark {
  scrollbar-color: variables.$white var(--mat-sys-surface);

  .only-on-dark {
    display: block;
  }

  .only-on-light {
    display: none;
  }

  // Tab styles
  .mat-mdc-tab-group.content-tabs {
    .mat-mdc-tab-header {
      background: variables.$primary-50 !important;
    }
    .mdc-tab.mat-mdc-tab {
      &.mdc-tab--active.mdc-tab-indicator--active {
        background: variables.$neutral-20;
      }
    }
  }
}
